@import '../../../styles.scss';

.commons--standard-container {
    align-items: center;
    justify-content: center;
    img {
        width: 600px;
        margin-bottom: 25px;
        @media screen and (max-width: $tablet-breakpoint) {
            width: 100%;
        }
    }
    h1, p {
        text-align: center;
    }
}